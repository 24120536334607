import noop from 'lodash/noop';
import {
    IQuestionnaireContentState,
    IQuestionnaireAnswersState,
} from '@pvolve/sdk/src/redux/selectors';
import { QUESTIONNAIRE_CATEGORY } from '@pvolve/sdk/src/app/modules/questionnaire/constants';
import usePostSubmitOnboarding from './usePostSubmitOnboarding';

export const usePostSubmit = (
    category: string,
    questionnaire: IQuestionnaireContentState,
    questionnaireSavedAnswers: IQuestionnaireAnswersState,
    postSubmitFinished: (enrolled?: boolean) => void
) => {
    const onboardingPostSubmit = usePostSubmitOnboarding(
        questionnaire,
        questionnaireSavedAnswers,
        postSubmitFinished
    );

    const postSubmitDictionary = {
        [`${QUESTIONNAIRE_CATEGORY.onboarding}`]: onboardingPostSubmit,
        [`${QUESTIONNAIRE_CATEGORY.feedback}`]: postSubmitFinished,
    };

    return postSubmitDictionary[category] || noop;
};

export default usePostSubmit;

import { isEmpty } from 'lodash';
import { ICustomDataState } from '@pvolve/sdk/src/redux/selectors';
import {
    QUESTIONNAIRE_CATEGORY,
    QUESTIONNAIRE_SUBCATEGORY,
    COHORT_CODES,
} from '@pvolve/sdk/src/app/modules/questionnaire/constants';

export interface dataStructure {
    value: string;
    'image-url': string;
}

interface basicTemplates {
    intro: string;
    outro: string;
}

interface cohortTemplateObject {
    [cohort: string]: basicTemplates;
}

const INTRO_TEMP_DEFAULT = 'templates/intro/BasicWelcomePage';
const OUTRO_TEMP_DEFAULT = 'templates/outro/BasicOnboardingOutroPage';

const getOnboardingTemplates = (): cohortTemplateObject => {
    const templateObject: cohortTemplateObject = {};
    for (let i = 0; i < COHORT_CODES.length; i++) {
        const cohort = COHORT_CODES[i];
        templateObject[cohort] = {
            intro: INTRO_TEMP_DEFAULT,
            outro: OUTRO_TEMP_DEFAULT,
        };
    }
    return templateObject;
};

export const INTRO_OUTRO_PAGE = {
    [QUESTIONNAIRE_CATEGORY.onboarding]: getOnboardingTemplates(),
    [QUESTIONNAIRE_CATEGORY.feedback]: {
        [QUESTIONNAIRE_SUBCATEGORY.series]: {
            intro: INTRO_TEMP_DEFAULT,
            outro: '',
        },
    },
};

export const formatValuesIntoAnswers = (values: any) => {
    let responses = [];
    for (const [key, value] of Object.entries(values)) {
        responses.push({ question_id: key, value });
    }
    return responses;
};

export const parseUserAttributeIntoMessage = (
    messageToParse: string,
    userAttributes,
    defaultMessage = ''
) => {
    const matches = messageToParse.match(/\{\{(.*?)\}\}/);
    if (matches) {
        const attributeName = matches[1];
        const attributeValue = userAttributes.object[attributeName];
        if (!isEmpty(attributeValue)) {
            return messageToParse.replace(`{{${attributeName}}}`, attributeValue);
        } else {
            return defaultMessage;
        }
    }

    return messageToParse;
};

export const questionnaireHasIntro = (
    questionnaireIntro: ICustomDataState,
    category: string,
    subcategory: string
) => !isEmpty(questionnaireIntro) && !isEmpty(INTRO_OUTRO_PAGE?.[category]?.[subcategory]?.intro);

export const questionnaireHasOutro = (
    questionnaireOutro: ICustomDataState,
    category: string,
    subcategory: string
) => !isEmpty(questionnaireOutro) && !isEmpty(INTRO_OUTRO_PAGE?.[category]?.[subcategory]?.outro);

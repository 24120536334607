import React from 'react';
import { INTRO_OUTRO_PAGE } from 'src/utils/questionnaire-utils';
import { ICustomDataState } from '@pvolve/sdk/src/redux/selectors';
import { ExtraContent } from 'src/components/shared';
import { navigate } from 'gatsby';

interface OutroPageProps {
    category: string;
    subCategory: string;
    outroContent: ICustomDataState;
    cta: string;
}

const OutroPage = ({ category, subCategory, outroContent, cta }: OutroPageProps) => {
    const onClickCTA = (cta: string) => () => navigate(cta);
    const template = INTRO_OUTRO_PAGE[category][subCategory]?.outro;
    const extraContentProps = {
        template,
        ...outroContent?.data,
        onClickCTA: onClickCTA(cta),
    };

    return <ExtraContent {...extraContentProps} />;
};

export default OutroPage;

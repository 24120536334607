import React from 'react';
import classNames from 'classnames';
import { Field } from 'formik';
import { IQuestionnaireInputQuestionTypeState } from '@pvolve/sdk/src/redux/selectors';

import * as Styles from 'src/styles/input-question.module.scss';
import { QUESTION_TYPE } from '@pvolve/sdk/src/app/modules/questionnaire/constants';
interface InpuQuestionProps {
    value: string;
    question: IQuestionnaireInputQuestionTypeState;
}

const InputQuestion = ({ value, question }: InpuQuestionProps) => {
    const { id: qId, subText: qSubText, limit: qLimit, type: qType, text: qText } = question;

    return (
        <main
            aria-label="main"
            className={classNames(
                'display-column',
                'alignItems--center',
                Styles.inputQuestionWrapper
            )}
        >
            {qText && (
                <h1 className={classNames('text-align--center bold', Styles.question)}>{qText}</h1>
            )}
            <div className={classNames(Styles.fieldWrapper)}>
                <Field
                    component={qType}
                    name={qId}
                    placeholder={qSubText}
                    maxLength={qLimit}
                    wrap={qType == QUESTION_TYPE.textarea ? 'soft' : ''}
                    value={value}
                />
            </div>
        </main>
    );
};

export default InputQuestion;

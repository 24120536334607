import React from 'react';
import { Button } from 'semantic-ui-react';
import Question from 'src/components/questionnairev2/shared/Question';
import { IQuestionnaireContentState } from '@pvolve/sdk/src/redux/selectors';
import SkipOption from 'src/components/questionnairev2/shared/SkipOption';

interface SinglePageQuestionnaireProps {
    questionnaireContent: IQuestionnaireContentState;
    values: any;
    canSkip?: boolean;
    onSkip?: () => void;
}

const SinglePageQuestionnaire = ({
    questionnaireContent,
    values,
    canSkip,
    onSkip,
}: SinglePageQuestionnaireProps) => {
    //TODO: Add logic to disable the submit button when the answers have not been answered and are required
    return (
        <div className="display-flex justify-content--center align-items--center padding--small">
            {canSkip && <SkipOption onSkip={onSkip} />}
            <div
                style={{ width: '600px' }}
                className="display-flex justify-content--center flex-direction--col"
            >
                {questionnaireContent.questions.map((question, index) => (
                    <div className="margin-bottom--large">
                        <Question question={question} value={values[question.id]} />
                    </div>
                ))}
                <Button primary type="submit" key="submit">
                    Done
                </Button>
            </div>
        </div>
    );
};

export default SinglePageQuestionnaire;

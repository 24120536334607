import React, { useState, useEffect } from 'react';
import { getQuestionnaire } from 'src/utils/sdk-utils';
import { useParams } from '@reach/router';
import { connect, ConnectedProps } from 'react-redux';
import { Container } from 'semantic-ui-react';
import { Spinner } from 'react-activity';
import { IState, ContentSelectors } from '@pvolve/sdk/src/redux/selectors';
import { redirect } from 'src/utils/url-utils';
import Selectors from 'src/state/root-selectors';
import { checkBetaUserPermit } from 'src/utils/featureFlag';
import { navigate } from 'gatsby';
import Pvolve from '@pvolve/sdk';
import { notifyBugsnagError } from 'src/utils/bugsnag';

import { QuestionFields } from 'src/components/questionnaire/Question';
import QuestionnaireForm, {
    QuestionnaireProps,
} from 'src/components/questionnaire/QuestionnaireForm';
import { EditPhasesParams } from 'src/components/shared/extra-content/templates/PnfMyPlan';
import * as Styles from 'src/styles/questionnaire.module.scss';

const connector = connect((state: IState) => {
    const seriesList = ContentSelectors.series.list(state);

    return {
        seriesList: seriesList,
        variants: Selectors.flags.phaseAndFunction(state),
    };
});

export interface IGetPastResponsesList {
    [key: string]: { question_id: string; value: string };
}

export interface PhaseAndFunctionQuestionnairePageTemplateProps
    extends ConnectedProps<typeof connector> {
    seriesList: Array<any>;
}

const getPastResponsesList = (
    questionnaireId: string,
    userQuestionsAttr: Array<any>
): IGetPastResponsesList => {
    const pastResponses = userQuestionsAttr.find((e) => e.id === questionnaireId);
    return pastResponses?.responses;
};

const getQuestionsList = ({ ...questionsListProps }): QuestionnaireProps['questions'] => {
    const {
        questionnaireId,
        questions,
        userQuestionsAttrState,
        updateResponsesState,
        ...params
    } = questionsListProps;
    const ifQuestionList = questions && questions.length > 0;
    const updatedQuestionsList = ifQuestionList && questions.slice();

    if (updateResponsesState) {
        const pastResponses =
            !!questionnaireId &&
            !!userQuestionsAttrState &&
            getPastResponsesList(questionnaireId, userQuestionsAttrState);
        {
            !!pastResponses &&
                !!updatedQuestionsList &&
                updatedQuestionsList.map((question: QuestionFields) => {
                    for (const response in pastResponses) {
                        if (question['slug'] === pastResponses[response]['question_id']) {
                            question['pastValue'] = pastResponses[response]['value'];
                        }
                    }
                });
        }
        if (params.updateResponses === EditPhasesParams.GOT_MY_PERIOD) {
            const lastPeriodDateQuestion = updatedQuestionsList?.filter(
                (e: { slug: string }) => e['slug'] === 'last_period_date'
            );
            return lastPeriodDateQuestion;
        }
        return updatedQuestionsList;
    } else {
        return questions;
    }
};

const getInitialValues = (questions: QuestionnaireProps['questions'], updateResponses: boolean) => {
    let initialValues: Array<{ question_id: string; value: string }> = [];
    if (updateResponses) {
        [...questions].map((question) => {
            const initialValue = {
                question_id: question.slug,
                value: question.pastValue,
            };
            initialValues.push(initialValue);
        });
    }
    return initialValues;
};

const extraContentString = (extraContent: Array<any>) => {
    if (extraContent) {
        const extraContentString = extraContent[0][Object.keys(extraContent[0])[0]];
        return extraContentString;
    }
};

const PhaseAndFunctionQuestionnairePageTemplate = ({
    seriesList,
    variants,
}: PhaseAndFunctionQuestionnairePageTemplateProps) => {
    const shouldRedirect = window && !!seriesList && seriesList.length === 0;

    const betaUser = checkBetaUserPermit(variants);

    useEffect(() => {
        if (!variants.loading && !betaUser) {
            navigate('/404');
        } else {
            if (shouldRedirect) {
                redirect('/series/phase-and-function');
            }
        }
    });

    const { ...params } = useParams();
    const currentSeries = seriesList.find(
        (e: { fields: { slug: string } }) => e.fields.slug === 'phase-and-function' // params.seriesSlug
    );
    const seriesId = currentSeries?.sys.id;
    const questionnaireId = currentSeries?.fields.questionnaire.sys.id;
    const gotMyPeriod = params.updateResponses === EditPhasesParams.GOT_MY_PERIOD;
    const [loadingState, setLoadingState] = useState<boolean>(true);
    const [questionnaireState, setQuestionnaireState] = useState<object>({});
    const [userQuestionsAttrState, setUserQuestionsAttrState] = useState<Array<any>>([]);
    const [questionList, setQuestionList] = useState<Array<any>>([]);
    const updateResponsesState = !!params.updateResponses;

    useEffect(() => {
        const getQuestionnaireData = async () => {
            try {
                if (updateResponsesState) {
                    const { object } = await Pvolve.api.account.getQuestionnairesAttributes();
                    {
                        !!object && setUserQuestionsAttrState(object.questionnaires);
                    }
                }
                const data = getQuestionnaire(questionnaireId);
                const { questionnaire } = await data;
                setQuestionnaireState(questionnaire);
            } catch (err) {
                notifyBugsnagError(err);
                throw new Error(err);
            } finally {
                setTimeout(() => {
                    setLoadingState(false);
                }, 250);
            }
        };
        getQuestionnaireData();
    }, [questionnaireId]);

    useEffect(() => {
        if (!!questionnaireState) {
            const questionsListProps = {
                questionnaireId,
                questions: questionnaireState?.questions,
                userQuestionsAttrState,
                updateResponsesState,
                ...params,
            };
            setQuestionList(getQuestionsList({ ...questionsListProps }));
        }
    }, [questionnaireState]);

    if (shouldRedirect) {
        return null;
    }

    return (
        <>
            {loadingState ? (
                <Container textAlign="center" className={Styles.spinnerContainer}>
                    {' '}
                    <Spinner className={Styles.spinner} size={40} speed={0.75} />{' '}
                </Container>
            ) : (
                <QuestionnaireForm
                    id={questionnaireState.id}
                    questions={questionList}
                    seriesId={seriesId}
                    updateResponses={updateResponsesState}
                    ctaAttr={questionnaireState.cta}
                    extraContent={
                        questionnaireState.extraContent &&
                        extraContentString(questionnaireState.extraContent)
                    }
                    initialValues={
                        updateResponsesState &&
                        questionList &&
                        getInitialValues(questionList, updateResponsesState)
                    }
                    gotMyPeriod={gotMyPeriod}
                    Styles={Styles}
                    type={questionnaireState.type}
                />
            )}
        </>
    );
};

export default connector(PhaseAndFunctionQuestionnairePageTemplate);

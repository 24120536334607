import React, { useState, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { CalendarPhase } from 'src/components/shared';
import NumberEntry from 'src/components/questionnaire/EntryType/NumberEntry';
import { getErrorMessage } from 'src/utils/form-utils';

interface QuestionResponseProps {
    id: string;
    slug: string;
    value: string | number;
    index: number;
    error: boolean;
}

export interface QuestionFields {
    // TODO: Move to SDK types content selectors
    id: string;
    slug: string;
    text: string;
    subtext: string;
    input: any;
    optional: boolean;
    pastValue: string;
    backgroundImage?: string;
}

interface QuestionProps {
    question: QuestionFields;
    questionIndex: number;
    updateResponse: (questionResponse: QuestionResponseProps) => void;
    positionedIndex: number;
    isWizard: boolean;
    Styles: React.CSSProperties;
}

const Question = ({
    question,
    questionIndex,
    updateResponse,
    positionedIndex,
    isWizard,
    Styles,
}: QuestionProps) => {
    const { id, slug, text, subtext, input, optional, pastValue } = question;
    const [initialize, setInitialize] = useState(true);
    const [inputValue, setInputValue] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const error = errorMessage.length > 0;

    useEffect(() => {
        if (initialize) {
            {
                !!pastValue && !inputValue && input.type != 'date' && setInputValue(pastValue);
            }
        }
        updateResponse({
            index: questionIndex,
            id,
            slug,
            value: inputValue,
            error,
        });
        !!initialize && setInitialize(false);
    }, [inputValue, pastValue]);

    const inputErrorCallback = (inputError: boolean) => {
        if (inputError) {
            setErrorMessage(input.errorMessage);
        } else {
            setErrorMessage('');
        }
    };

    const EntryProps = {
        input,
        required: optional === false,
        updateValue: setInputValue,
        inputErrorCallback,
        error,
    };

    return !isWizard || questionIndex === positionedIndex ? (
        <Grid.Row className={Styles.questionRow}>
            <Grid.Row className="margin-bottom--xlarge">
                <h2 className={`${Styles.questionTitle}`}>{text}</h2>
                <p className="p1">{subtext}</p>
                {errorMessage !== '' && getErrorMessage(errorMessage, true)}
            </Grid.Row>

            {question.input.type === 'number' && (
                <NumberEntry {...EntryProps} pastValue={inputValue} Styles={Styles} />
            )}
            {question.input.type === 'date' && (
                <Grid.Row className="margin-bottom--large">
                    <CalendarPhase
                        input={input}
                        updateValue={setInputValue}
                        currentValue={inputValue && inputValue !== '' ? new Date(inputValue) : null}
                    />
                </Grid.Row>
            )}
        </Grid.Row>
    ) : null;
};

export default Question;

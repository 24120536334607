import React from 'react';
import { noop } from 'lodash';
import { Icon } from 'src/components/shared';
import * as Styles from 'src/styles/skip-option.module.scss';

interface SkipOptionProps {
    onSkip?: () => void;
    size?: number;
}

const SkipOption = ({ onSkip, size }: SkipOptionProps) => {
    return (
        <div className={Styles.skipOption}>
            <Icon onClick={onSkip} name="pv-close" color="black" size={size} />
        </div>
    );
};

SkipOption.defaultProps = {
    onSkip: noop,
    size: 12,
};

export default SkipOption;

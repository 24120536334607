import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import Question from 'src/components/questionnairev2/shared/Question';
import WizardHandler from 'src/components/questionnairev2/wizardComponents/WizardHandler';
import { IQuestionnaireContentState } from '@pvolve/sdk/src/redux/selectors';
import SkipOption from 'src/components/questionnairev2/shared/SkipOption';

import * as Styles from 'src/styles/wizard-questionnaire.module.scss';
interface WizardQuestionnaireProps {
    questionnaireContent: IQuestionnaireContentState;
    values: any;
    setShowIntro: any;
    hasIntro: boolean;
    canSkip?: boolean;
    onSkip?: () => void;
}

const WizardQuestionnaire = ({
    questionnaireContent,
    values,
    setShowIntro,
    hasIntro,
    canSkip,
    onSkip,
}: WizardQuestionnaireProps) => {
    const questionsLength = questionnaireContent?.questions?.length - 1;
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    const currentQuestion = questionnaireContent?.questions[currentQuestionIndex];
    const columns = questionnaireContent?.questionnaireTemplate?.columns || 1;
    const isTwoColumnsLayout = columns > 1;
    const questionWrapperClass = isTwoColumnsLayout ? '' : Styles.questionWrapperOneColumn;

    return (
        currentQuestion && (
            <div
                className={classNames(
                    'justify-content--center align-items--center',
                    Styles.container
                )}
            >
                {canSkip && <SkipOption onSkip={onSkip} />}
                {isTwoColumnsLayout && !isEmpty(currentQuestion.image) && (
                    <div>
                        <img src={currentQuestion.image} alt="alt text" />
                    </div>
                )}
                <div className={questionWrapperClass}>
                    <Question question={currentQuestion} value={values[currentQuestion.id]} />
                    <WizardHandler
                        hasIntro={hasIntro}
                        currentAnswer={values[currentQuestion.id]}
                        questionsLength={questionsLength}
                        currentQuestionIndex={currentQuestionIndex}
                        setCurrentQuestionIndex={setCurrentQuestionIndex}
                        setShowIntro={setShowIntro}
                        isQuestionOptional={currentQuestion.optional}
                    />
                </div>
            </div>
        )
    );
};

export default WizardQuestionnaire;

import React from 'react';
import {
    SelectionQuestion,
    RatingQuestion,
    InputQuestion,
} from 'src/components/questionnairev2/shared';
import { QUESTION_TYPE } from '@pvolve/sdk/src/app/modules/questionnaire/constants';
import {
    IQuestionnaireSelectionQuestionTypeState,
    IQuestionnaireInputQuestionTypeState,
    IQuestionnaireRatingQuestionTypeState,
} from '@pvolve/sdk/src/redux/selectors';

interface QuestionProps {
    question:
        | IQuestionnaireSelectionQuestionTypeState
        | IQuestionnaireInputQuestionTypeState
        | IQuestionnaireRatingQuestionTypeState;
    value: any;
}

const Question = ({ question, value }: QuestionProps) => {
    //TODO: add the input question component
    switch (question?.type) {
        case QUESTION_TYPE.selection:
            return <SelectionQuestion value={value} question={question} />;
        case QUESTION_TYPE.rating:
            return <RatingQuestion value={value ? value : 0} question={question} />;
        case QUESTION_TYPE.textarea:
            return <InputQuestion value={value} question={question} />;
        default:
            return null;
    }
};

export default Question;

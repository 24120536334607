import React, { useEffect, useState } from 'react';
import { Form, Grid } from 'semantic-ui-react';

interface EntryProps {
    required: boolean;
    updateValue: (value: string) => void;
    inputErrorCallback: (inputError: boolean) => void;
    error: boolean;
    pastValue: string;
    input: {
        range: string;
        unit: string;
    };
    Styles: React.CSSProperties;
}

const NumberEntry = ({
    required,
    updateValue,
    inputErrorCallback,
    error,
    pastValue,
    input,
    Styles,
}: EntryProps) => {
    const [inputValue, setInputValue] = useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let errorResponse = false;

        const stringVal = e.target.value;
        const valueNUMB = parseFloat(stringVal);

        if (valueNUMB < 1 || !Number.isInteger(valueNUMB)) {
            errorResponse = true;
        } else if (input.range && input.range.length > 1) {
            /* if value is not between range, show error */
            if (valueNUMB < parseInt(input.range[0]) || valueNUMB > parseInt(input.range[1])) {
                errorResponse = true;
            } else if (error) {
                errorResponse = false;
            }
        }

        inputErrorCallback(errorResponse);
        if (isNaN(valueNUMB)) {
            setInputValue(stringVal);
            updateValue(stringVal);
        } else {
            setInputValue(valueNUMB.toString());
            updateValue(valueNUMB.toString());
        }
    };

    useEffect(() => {
        setInputValue(pastValue);

        const inputElement = document.getElementById('inputQuestion');
        if (!!inputElement && inputElement != document.activeElement) {
            inputElement.focus();
        }
    }, [pastValue]);

    return (
        <Grid.Row className={`${Styles.inputRow} margin-bottom--xlarge`}>
            <Form.Field>
                <Form.Input
                    id="inputQuestion"
                    name="inputQuestion"
                    type="number"
                    value={inputValue}
                    onChange={(e) => {
                        handleChange(e);
                    }}
                    min="0"
                    required={required}
                    error={error}
                />
            </Form.Field>
            <p>{input.unit}</p>
        </Grid.Row>
    );
};

export default NumberEntry;

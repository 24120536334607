// extracted by mini-css-extract-plugin
export const questionnaireWrapper = "questionnaire-module--questionnaire-wrapper--3sN5h";
export const gridContent = "questionnaire-module--grid-content--tlB4-";
export const gridContentNoBg = "questionnaire-module--grid-content-no-bg--2wTSL";
export const questionnaireImageCol = "questionnaire-module--questionnaire-image-col--3Syc4";
export const questionContainerCol = "questionnaire-module--question-container-col--31tzn";
export const questionIntroCol = "questionnaire-module--question-intro-col--3ZIzS";
export const prevArrow = "questionnaire-module--prev-arrow--3dHEM";
export const backToPill = "questionnaire-module--back-to-pill--19iwr";
export const buttonRow = "questionnaire-module--button-row--2hAo6";
export const startButton = "questionnaire-module--start-button--1yaWw";
export const questionRow = "questionnaire-module--question-row--2zSsw";
export const questionTitle = "questionnaire-module--question-title--2CvnB";
export const inputRow = "questionnaire-module--input-row--2t7cY";
export const spinnerContainer = "questionnaire-module--spinner-container--nXvRh";
export const spinner = "questionnaire-module--spinner--Mkmi4";
export const questionnaireProgressBarRow = "questionnaire-module--questionnaire-progress-bar-row--8Aio-";
export const questionnaireProgressBar = "questionnaire-module--questionnaire-progress-bar--3K-4Z";
export const iconWrapper = "questionnaire-module--iconWrapper--2jHOt";
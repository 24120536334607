import React, { useCallback } from 'react';
import { Button } from 'semantic-ui-react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import * as Styles from 'src/styles/wizard-questionnaire.module.scss';
interface WizardHandlerProps {
    hasIntro: boolean;
    currentAnswer: any;
    questionsLength: number;
    currentQuestionIndex: number;
    setCurrentQuestionIndex: (index: number) => void;
    setShowIntro: any;
    isQuestionOptional: boolean;
}

const WizardHandler = ({
    hasIntro,
    currentAnswer,
    questionsLength,
    setCurrentQuestionIndex,
    currentQuestionIndex,
    setShowIntro,
    isQuestionOptional,
}: WizardHandlerProps) => {
    const nextDisabled = isEmpty(currentAnswer) && !isQuestionOptional;
    const backHidden = currentQuestionIndex === 0 && !hasIntro;

    const goBack = useCallback(() => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        } else {
            if (hasIntro) {
                setShowIntro(true);
            }
        }
    }, [currentQuestionIndex, hasIntro, setCurrentQuestionIndex, setShowIntro]);

    const goForward = useCallback(() => {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
    }, [currentQuestionIndex, setCurrentQuestionIndex]);

    return (
        <section
            aria-label="Progress controls"
            className={classNames(
                Styles.wizardHandlerContainer,
                'alignItems--center',
                'justifyContent-space-between'
            )}
        >
            <Button
                type="button"
                key="notsubmitback"
                primary
                onClick={goBack}
                disabled={backHidden}
                className={classNames({ buttonHidden: backHidden })}
            >
                Back
            </Button>
            <label className={classNames('l1 accent bold', Styles.indicator)}>{`${
                currentQuestionIndex + 1
            } of ${questionsLength + 1}`}</label>
            {currentQuestionIndex === questionsLength ? (
                <Button primary disabled={nextDisabled} type="submit" key="submit">
                    Done
                </Button>
            ) : (
                <Button
                    aria-disabled={nextDisabled}
                    type="button"
                    key="notsubmitnext"
                    primary
                    onClick={goForward}
                    disabled={nextDisabled}
                >
                    Next
                </Button>
            )}
        </section>
    );
};

export default WizardHandler;

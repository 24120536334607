import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from '@reach/router';
import { navigate } from 'gatsby';
import isEmpty from 'lodash/isEmpty';
import { Form, Formik } from 'formik';
import {
    formatValuesIntoAnswers,
    questionnaireHasIntro,
    questionnaireHasOutro,
} from 'src/utils/questionnaire-utils';
import WizardQuestionnaire from 'src/components/questionnairev2/wizardComponents/WizardQuestionnaire';
import SinglePageQuestionnaire from 'src/components/questionnairev2/singlePageComponents/SinglePageQuestionnaire';
import TransitionPage from 'src/components/shared/TransitionPage';
import IntroPage from 'src/components/questionnairev2/shared/IntroPage';
import OutroPage from 'src/components/questionnairev2/shared/OutroPage';
import usePostSubmit from 'src/components/questionnairev2/hooks/usePostSubmit';

import {
    QUESTIONNAIRE_TYPE,
    QUESTIONNAIRE_SUBCATEGORY,
} from '@pvolve/sdk/src/app/modules/questionnaire/constants';
import Actions from '@pvolve/sdk/src/app/actions';
import RootActions from 'src/state/root-actions';
import Selectors from 'src/state/root-selectors';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import classNames from 'classnames';
import { notifyBugsnagError } from 'src/utils/bugsnag';

const QuestionnairePageTemplate = () => {
    const { ...params } = useParams();
    const { category, subcategory } = params;

    const dispatch = useDispatch();
    const questionnaire = useSelector(Selectors.questionnaire.questionnaireContent);
    const questionnaireSavedAnswers = useSelector(Selectors.account.questionnaireSavedAnswers);
    const currentSeries = useSelector(PvolveSelectors.series.enrollment.current);

    const postSubmitFinished = useCallback(
        (enrolled?: boolean) => {
            if (questionnaireSavedAnswers?.skip && !enrolled) {
                navigate(questionnaire?.cta);
            } else {
                if (questionnaireHasOutro(questionnaire?.outro, category, subcategory)) {
                    setTimeout(() => {
                        setLoading({ active: false, message: '' });
                        setShowOutro(true);
                        setShowIntro(false);
                    }, 3000);
                } else {
                    setTimeout(() => {
                        navigate(questionnaire?.cta);
                    }, 3000);
                }
            }
        },
        [category, questionnaire, questionnaireSavedAnswers, subcategory]
    );

    const postSubmit = usePostSubmit(
        category,
        questionnaire,
        questionnaireSavedAnswers,
        postSubmitFinished
    );

    const [loading, setLoading] = useState<{ active: boolean; message: string }>({
        active: false,
        message: '',
    });
    const [showIntro, setShowIntro] = useState<boolean>(false);
    const [showOutro, setShowOutro] = useState<boolean>(false);
    const [formikInitialValues, setFormikInitialValues] = useState({});

    /**
     * Skips questionnaire
     */
    const onSkip = useCallback(async () => {
        setLoading({ active: true, message: '' });
        const responses = questionnaire?.questions?.map((question) => {
            return { question_id: question?.id, value: null };
        });
        const questionnaireResponse = {
            id: questionnaire?.id,
            category: questionnaire?.category,
            subcategory: questionnaire?.subcategory,
            responses,
            skip: true,
            series_id:
                questionnaire?.subcategory === QUESTIONNAIRE_SUBCATEGORY.series
                    ? currentSeries?.series_id
                    : '',
        };

        try {
            dispatch(
                RootActions.account.submitQuestionnaireAnswers.trigger({
                    questionnaireResponse,
                })
            );
        } catch (error) {
            setLoading({ active: false, message: '' });
        }
    }, [dispatch, questionnaire, currentSeries]);

    /**
     * Submits questionnaire answers
     */
    const onSubmit = useCallback(
        async (values: any) => {
            setLoading({ active: true, message: questionnaire?.outroTransitionLabel || '' });
            const responses = formatValuesIntoAnswers(values);

            const questionnaireResponse = {
                id: questionnaire.id,
                category: questionnaire?.category,
                subcategory: questionnaire?.subcategory,
                responses,
                skip: false,
                series_id:
                    questionnaire?.subcategory === QUESTIONNAIRE_SUBCATEGORY.series
                        ? currentSeries?.series_id
                        : '',
            };

            try {
                dispatch(
                    RootActions.account.submitQuestionnaireAnswers.trigger({
                        questionnaireResponse,
                    })
                );
                dispatch(
                    RootActions.segment.track.questionnaireQuestionAnswered({
                        questionnaire,
                        values,
                    })
                );
            } catch (error) {
                notifyBugsnagError(error);
                setLoading({ active: false, message: '' });
            }
        },
        [currentSeries, questionnaire, dispatch]
    );

    /**
     * After submitting the form, running any additional logic if needed and displaying outro if exists
     */
    useEffect(() => {
        if (!!questionnaireSavedAnswers) {
            postSubmit();
        }
    }, [questionnaireSavedAnswers, postSubmit]);

    /**
     * After getting the questionnaire content, initializing formik and displaying intro if exists
     */
    useEffect(() => {
        if (!!questionnaire) {
            setLoading({
                active: true,
                message: questionnaire?.introTransitionLabel || '',
            });
            setFormikInitialValues(
                questionnaire?.questions?.reduce((prev: any, curr: any) => {
                    return {
                        ...prev,
                        [curr.id]: null,
                    };
                }, {})
            );
            if (questionnaireHasIntro(questionnaire?.intro, category, subcategory)) {
                setShowIntro(true);
                setShowOutro(false);
            }

            setTimeout(() => {
                setLoading({ active: false, message: '' });
            }, 3000);
        }
    }, [category, questionnaire, subcategory]);

    /**
     * When component mounts, getting the questionnaire content
     */
    useEffect(() => {
        setLoading({
            active: true,
            message: '',
        });
        dispatch(Actions.questionnaire.cleanQuestionnaire());
        dispatch(
            Actions.questionnaire.loadQuestionnaireContent.trigger({
                category,
                subcategory,
            })
        );
    }, []);

    /**
     * Gets the correct type of questionnaire to render
     */
    const renderQuestionnaire = useCallback(
        (values: any) => {
            switch (questionnaire.type) {
                case QUESTIONNAIRE_TYPE.wizard:
                    return (
                        <WizardQuestionnaire
                            questionnaireContent={questionnaire}
                            values={values}
                            setShowIntro={setShowIntro}
                            hasIntro={questionnaireHasIntro(
                                questionnaire?.intro,
                                category,
                                subcategory
                            )}
                            canSkip={questionnaire?.optional}
                            onSkip={onSkip}
                        />
                    );
                case QUESTIONNAIRE_TYPE.singlePage:
                    return (
                        <SinglePageQuestionnaire
                            questionnaireContent={questionnaire}
                            values={values}
                            canSkip={questionnaire?.optional}
                            onSkip={onSkip}
                        />
                    );
                default:
                    return null;
            }
        },
        [category, onSkip, questionnaire, subcategory]
    );

    return (
        <>
            {loading.active || isEmpty(questionnaire) || isEmpty(formikInitialValues) ? (
                <TransitionPage message={loading.message} />
            ) : (
                <>
                    {showIntro && (
                        <IntroPage
                            category={category}
                            subCategory={subcategory}
                            introContent={questionnaire?.intro}
                            setShowIntro={setShowIntro}
                            canSkip={questionnaire?.optional}
                            onSkip={onSkip}
                        />
                    )}
                    <div className={classNames({ 'display--none': showIntro || showOutro })}>
                        <Formik initialValues={formikInitialValues} onSubmit={onSubmit}>
                            {({ values }) => {
                                return <Form>{renderQuestionnaire(values)}</Form>;
                            }}
                        </Formik>
                    </div>
                    {showOutro && (
                        <OutroPage
                            category={category}
                            subCategory={subcategory}
                            outroContent={questionnaire?.outro}
                            cta={questionnaire.cta}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default QuestionnairePageTemplate;

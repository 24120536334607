import React from 'react';
import { INTRO_OUTRO_PAGE } from 'src/utils/questionnaire-utils';
import { ICustomDataState } from '@pvolve/sdk/src/redux/selectors';
import { ExtraContent } from 'src/components/shared';

interface IntroPageProps {
    category: string;
    subCategory: string;
    introContent: ICustomDataState;
    setShowIntro: any;
    canSkip?: boolean;
    onSkip?: () => void;
}

const IntroPage = ({
    category,
    subCategory,
    introContent,
    setShowIntro,
    canSkip,
    onSkip,
}: IntroPageProps) => {
    const onClickCTA = () => () => setShowIntro(false);
    const template = INTRO_OUTRO_PAGE[category][subCategory]?.intro;
    const extraContentProps = {
        template,
        ...introContent?.data,
        onClickCTA: onClickCTA(),
        canSkip,
        onSkip,
    };

    return <ExtraContent {...extraContentProps} />;
};

export default IntroPage;

import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {
    IQuestionnaireContentState,
    IQuestionnaireAnswersState,
} from '@pvolve/sdk/src/redux/selectors';
import { getSeriesToJoinOnboarding } from '@pvolve/sdk/src/app/modules/questionnaire/utils';
import Actions from '@pvolve/sdk/src/app/actions';
import Selectors from 'src/state/root-selectors';
import { USER_COHORT } from '@pvolve/sdk/src/app/modules/auth/constants';
import { useEffect, useState, useCallback } from 'react';
import { EnrollmentStatuses } from '@pvolve/sdk/src/redux/selectors';

import { linkToExternal } from 'src/utils/url-utils';
import { ZELDA_HOME_LINK } from 'src/constants/url-constants';

export const usePostSubmitOnboarding = (
    questionnaire: IQuestionnaireContentState,
    questionnaireSavedAnswers: IQuestionnaireAnswersState,
    postSubmitFinished: (enrolled?: boolean) => void
) => {
    const dispatch = useDispatch();
    const userCohort = useSelector(Selectors.account.userCohort);
    const enrollementHistory = useSelector(Selectors.series.enrollment.history);

    const [seriesId, setSeriesId] = useState(null);

    useEffect(() => {
        const enrolledSeriesStatus = enrollementHistory?.[seriesId]?.status;
        if (enrolledSeriesStatus === EnrollmentStatuses.enrolled) {
            postSubmitFinished(true);
        }
    }, [enrollementHistory, seriesId, postSubmitFinished]);

    const onboardingPostSubmit = useCallback(async () => {
        if (
            (userCohort === USER_COHORT.d2c || userCohort === USER_COHORT.trialist) &&
            questionnaireSavedAnswers?.skip
        ) {
            linkToExternal(ZELDA_HOME_LINK);
        } else {
            const series = await getSeriesToJoinOnboarding(
                questionnaire,
                questionnaireSavedAnswers,
                userCohort
            );
            if (!isEmpty(series)) {
                setSeriesId(series?.id);
                dispatch(Actions.series.join.trigger({ seriesId: series?.id }));
            } else {
                postSubmitFinished();
            }
        }
    }, [dispatch, postSubmitFinished, questionnaire, questionnaireSavedAnswers, userCohort]);

    return onboardingPostSubmit;
};

export default usePostSubmitOnboarding;

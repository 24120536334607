import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Field } from 'formik';
import { IQuestionnaireSelectionQuestionTypeState } from '@pvolve/sdk/src/redux/selectors';

import * as Styles from 'src/styles/selection-question.module.scss';
interface SelectionQuestionProps {
    value?: string | string[];
    question: IQuestionnaireSelectionQuestionTypeState;
}

const SelectionQuestion = ({ value, question }: SelectionQuestionProps) => {
    const optionClass = question?.displayLikePills ? Styles.optionPill : Styles.option;
    const fieldWiderWrapperClass = question?.displayLikePills ? Styles.fieldWiderWrapper : '';
    const {
        id: qId,
        options: qOptions,
        allowMultiple: qAllowMultiple,
        subText: qSubText,
        text: qText,
    } = question;

    const isOptionSelected = useCallback(
        (optionId: string) => {
            if (!!value) {
                if (qAllowMultiple) {
                    return value.includes(optionId);
                }
                return value === optionId;
            }
            return false;
        },
        [question, value]
    );

    return (
        <main
            aria-label="main"
            className={classNames(
                'display-column',
                'alignItems--center',
                Styles.selectionQuestionWrapper
            )}
        >
            {qText && (
                <h1 className={classNames('text-align--center bold', Styles.question)}>{qText}</h1>
            )}
            {qSubText && (
                <label className={classNames('l1 accent bold', Styles.subText)}>{qSubText}</label>
            )}
            <div className={classNames(Styles.fieldWrapper, fieldWiderWrapperClass)} role="group">
                {qOptions &&
                    qOptions.map((optionItem: any, index: number) => {
                        return (
                            <label
                                aria-checked={isOptionSelected(optionItem?.id)}
                                aria-label={optionItem?.optionText}
                                key={index}
                                className={classNames('justify-content--center huge', optionClass, {
                                    optionSelected: isOptionSelected(optionItem?.id),
                                })}
                                role={qAllowMultiple ? 'checkbox' : 'radio'}
                                tabIndex={0}
                            >
                                <Field
                                    type={qAllowMultiple ? 'checkbox' : 'radio'}
                                    name={qId}
                                    value={optionItem?.id}
                                />
                                {optionItem?.optionText}
                            </label>
                        );
                    })}
            </div>
        </main>
    );
};

export default SelectionQuestion;

import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { navigate } from 'gatsby';
import { map } from 'lodash';
import Pvolve from '@pvolve/sdk';
import Actions from '@pvolve/sdk/src/app/actions';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import Selectors from 'src/state/root-selectors';
import { useLocation, WindowLocation } from '@reach/router';
import { IState } from '@pvolve/sdk/src/redux/selectors';
import { BackToPill, SquareCloseButton } from 'src/components/shared';
import { Button, Form, Grid } from 'semantic-ui-react';
import Question from 'src/components/questionnaire/Question';
import QuestionnaireProgress from 'src/components/questionnaire/QuestionnaireProgress';
import { getErrorMessage } from 'src/utils/form-utils';
import { ExtraContent } from 'src/components/shared';
import { LocationState } from 'src/components/shared/SquareCloseButton';
import { Breakpoints, Directions, useBreakpoints } from 'src/utils/breakpoint-utils';

const mapStateToProps = (state: IState, { seriesId }: { seriesId: string }) => {
    const currentEnrollment = PvolveSelectors.series.enrollment.current(state);

    return {
        enrolled: currentEnrollment?.series_id === seriesId,
        phaseAttributes: Selectors.phases.currentPhases(state),
    };
};

const mapDispatchToProps = {
    joinSeries: (seriesId: string) => Actions.series.join.trigger({ seriesId }),
    listPhases: () => Actions.phases.list.trigger(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const getImagesList = (questions: QuestionnaireProps['questions']) =>
    [...questions].map((question) => question.backgroundImage);

export interface QuestionnaireProps extends ConnectedProps<typeof connector> {
    id: string | undefined;
    questions: [{ [x: string]: any }];
    seriesId: string | undefined;
    updateResponses: boolean;
    ctaAttr: string;
    extraContent?: string;
    initialValues?: [{ question_id: string; value: string }];
    gotMyPeriod: boolean;
    Styles: React.CSSProperties;
    type: 'WIZARD' | 'FORM';
}

const QuestionnaireForm = ({
    joinSeries,
    listPhases,
    id,
    questions,
    seriesId,
    updateResponses,
    ctaAttr,
    extraContent,
    phaseAttributes,
    initialValues,
    gotMyPeriod,
    Styles,
    type,
}: QuestionnaireProps) => {
    const questionsLength = questions?.length - 1;
    const isTabletPortrait = useBreakpoints(Breakpoints.lg, Directions.down);
    const isMobile = useBreakpoints(Breakpoints.sm, Directions.down);
    const isWizard = type == 'WIZARD' ? true : false;

    const [questionResponses, setQuestionResponses] = useState(Array(questions?.length));
    const [questionnaireErrors, setQuestionnaireErrors] = useState(
        Array(questions?.length).fill({ error: false })
    );
    const [errorSubmit, setErrorSubmit] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [disableNext, setDisableNext] = useState(true);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentImage, setCurrentImage] = useState('');
    const [showIntro, setShowIntro] = useState(false);
    const [showError, setShowError] = useState(false);
    const [updateError, setUpdateError] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const imagesList = getImagesList(questions);
    const { state } = useLocation() as WindowLocation<LocationState>;

    const updateResponse = ({ ...questionResponse }): void => {
        if (!disableSubmit) {
            /* disable button until all values are passed */
            setDisableSubmit(true);
        }
        const questionResponsesUpdate = questionResponses;
        const questionnaireErrorsUpdate = questionnaireErrors;
        const updatedResponse = {
            question_id: questionResponse?.slug,
            value: questionResponse.error ? '' : questionResponse?.value,
        };
        questionResponsesUpdate[questionResponse?.index] = updatedResponse;
        questionnaireErrorsUpdate[questionResponse?.index] = { error: questionResponse?.error };
        setQuestionResponses(questionResponsesUpdate);
        setQuestionnaireErrors(questionnaireErrorsUpdate);
        checkResponses();
    };

    const handleSubmit = (responses: any[]) => {
        const questionnaireResponse = {
            id: id,
            responses: [...responses],
        };

        async function submit(questionnaireResponse: { id?: string; responses: any[] }) {
            if (!updateResponses && seriesId) {
                try {
                    await Pvolve.api.account.saveQuestionnairesAttributes(questionnaireResponse);
                } catch (error) {
                    console.log(error);
                    setErrorSubmit(true);
                } finally {
                    setSubmitting(true);
                }
            } else {
                try {
                    await Pvolve.api.account.updateQuestionnairesAttributes(questionnaireResponse);
                } catch (error) {
                    console.log(error);
                    setErrorSubmit(true);
                } finally {
                    setSubmitting(true);
                }
            }
        }

        submit(questionnaireResponse);
    };

    const checkResponses = () => {
        if (questions) {
            let disableNext = false;
            if (questionnaireErrors[currentQuestionIndex].error) {
                disableNext = true;
            } else {
                if (
                    questionResponses[currentQuestionIndex]?.value === '' &&
                    questions[currentQuestionIndex].optional === false
                ) {
                    disableNext = true;
                }
            }
            setDisableNext(disableNext);
            const disableSubmit = questionResponses.some(
                (response, index) =>
                    !!questionnaireErrors[index].error ||
                    (response.value === '' && questions[index].optional === false)
            );
            setDisableSubmit(disableSubmit);
        }
    };

    const changeQuestion = (position: string) => {
        let newIndex;
        if (position === 'previous') {
            newIndex = currentQuestionIndex - 1;
        } else {
            newIndex = currentQuestionIndex + 1;
        }
        if (questionResponses[newIndex]) {
            /* load previous value if it exists */
            questions[newIndex]['pastValue'] = questionResponses[newIndex].value;
        }
        setCurrentImage(imagesList[newIndex]);
        setCurrentQuestionIndex(newIndex);
    };

    const getCloseIconSize = (): number => {
        if (isTabletPortrait) {
            return isMobile ? 10 : 13;
        }

        return 16;
    };

    useEffect(() => {
        checkResponses();
    }, [currentQuestionIndex]);

    useEffect(() => {
        setCurrentImage(imagesList[currentQuestionIndex]);
    }, [imagesList]);

    useEffect(() => {
        if (extraContent) {
            setShowIntro(true);
        }
    }, [extraContent]);

    useEffect(() => {
        submitting && listPhases();
    }, [submitting]);

    useEffect(() => {
        if (submitting) {
            /* AFTER SUBMITTING */
            setSubmitting(false);
            if (phaseAttributes.hasNegativePhase) {
                if (updateResponses && seriesId) {
                    /* UPDATE */
                    setUpdateError(true);
                    handleSubmit(initialValues);
                } else {
                    setShowError(true);
                }
            } else {
                if (!updateResponses && seriesId) {
                    /* ONBOARDING */
                    joinSeries(seriesId);
                    navigate(ctaAttr);
                } else {
                    /* UPDATE */
                    if (!updateError) {
                        const prevPath = state?.prevPath ? state.prevPath : -1;
                        gotMyPeriod ? navigate(prevPath) : navigate(ctaAttr);
                    } else {
                        setShowError(true);
                    }
                }
            }
        }
    }, [phaseAttributes]);

    return questions ? (
        <div className={Styles.questionnaireWrapper}>
            {showError ? (
                <ExtraContent template={'templates/PnfOnboardingError'} Styles={Styles} />
            ) : showIntro && !updateResponses ? (
                <ExtraContent template={extraContent} showIntro={setShowIntro} Styles={Styles} />
            ) : (
                <Form>
                    <Grid
                        className={isWizard ? Styles.gridContent : Styles.gridContentNoBg}
                        columns={isWizard ? 2 : 1}
                    >
                        {isWizard && (
                            <Grid.Column className={Styles.questionnaireImageCol}>
                                <img src={currentImage} alt="alternatetext"></img>
                            </Grid.Column>
                        )}
                        <Grid.Column className={`${Styles.questionContainerCol}`}>
                            <Grid.Row className={Styles.prevArrow}>
                                {isWizard && currentQuestionIndex !== 0 && (
                                    <div
                                        onClick={() => {
                                            changeQuestion('previous');
                                        }}
                                    >
                                        <BackToPill
                                            className={Styles.backToPill}
                                            bgcolor="transparent"
                                            label="Back"
                                            withClickHandle={false}
                                        />
                                    </div>
                                )}
                            </Grid.Row>
                            <Grid.Row
                                className={`${Styles.questionnaireProgressBarRow} margin-bottom--large`}
                            >
                                {isWizard && (
                                    <QuestionnaireProgress
                                        currentQuestion={currentQuestionIndex + 1}
                                        totalQuestions={questions?.length}
                                        Styles={Styles}
                                    />
                                )}
                            </Grid.Row>
                            {map(questions, (question, index) => {
                                return (
                                    <Question
                                        key={index}
                                        question={question}
                                        questionIndex={index}
                                        updateResponse={updateResponse}
                                        positionedIndex={currentQuestionIndex}
                                        isWizard={isWizard}
                                        Styles={Styles}
                                    />
                                );
                            })}
                            {errorSubmit && (
                                <Grid.Row>{getErrorMessage('Error submitting the form')}</Grid.Row>
                            )}
                            <Grid.Row className={Styles.buttonRow}>
                                {isWizard ? (
                                    questionsLength === currentQuestionIndex ? (
                                        <Button
                                            size="large"
                                            floated="right"
                                            type="button"
                                            disabled={disableSubmit}
                                            onClick={() => handleSubmit(questionResponses)}
                                        >
                                            SUBMIT
                                        </Button>
                                    ) : (
                                        <Button
                                            size="large"
                                            floated="right"
                                            type="button"
                                            disabled={disableNext}
                                            onClick={() => changeQuestion('next')}
                                        >
                                            NEXT
                                        </Button>
                                    )
                                ) : (
                                    <Button
                                        size="large"
                                        floated="right"
                                        type="button"
                                        disabled={disableSubmit}
                                        onClick={() => handleSubmit(questionResponses)}
                                    >
                                        SUBMIT
                                    </Button>
                                )}
                            </Grid.Row>
                        </Grid.Column>
                    </Grid>
                </Form>
            )}
            <SquareCloseButton size={getCloseIconSize()} />
        </div>
    ) : null;
};

export default connector(QuestionnaireForm);

import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import Selectors from 'src/state/root-selectors';
import classNames from 'classnames';
import { Field } from 'formik';
import { IQuestionnaireRatingQuestionTypeState } from '@pvolve/sdk/src/redux/selectors';
import { Icon } from 'src/components/shared';
import { parseUserAttributeIntoMessage } from 'src/utils/questionnaire-utils';

import * as Styles from 'src/styles/rating-question.module.scss';
interface RatingQuestionProps {
    value: number;
    question: IQuestionnaireRatingQuestionTypeState;
}

const RatingQuestion = ({ value, question }: RatingQuestionProps) => {
    const userAttributes = useSelector(Selectors.account.userAttributes);
    const {
        id: qId,
        completedWorkText: qCompletedWorkText,
        congratulatoryText: qCongratulatoryText,
        text: qText,
    } = question;

    const renderOptions = useCallback(() => {
        var fields = [];
        for (var i = 1; i < 6; i++) {
            fields.push(
                <div key={i}>
                    <Field
                        type="radio"
                        name={qId}
                        value={i}
                        id={`${qId}-${i}`}
                        key={`${qId}-${i}`}
                    />
                    <label htmlFor={`${qId}-${i}`} key={`${qId}-${i}-label`}>
                        <Icon size={50} name="pv-star" color={value >= i ? 'black' : 'gray-30'} />
                    </label>
                </div>
            );
        }
        return fields;
    }, [question, value]);

    return (
        <main
            aria-label="main"
            className={classNames(
                'display-column',
                'alignItems--center',
                'justifyContent-center',
                Styles.ratingQuestionWrapper
            )}
        >
            {qCompletedWorkText && (
                <h1
                    className={classNames(
                        'upper text-align--center huge36',
                        Styles.completedWorkText
                    )}
                >
                    {qCompletedWorkText}
                </h1>
            )}
            {qCongratulatoryText && (
                <h1
                    className={classNames(
                        'bold text-align--center massive',
                        Styles.congratulatoryText
                    )}
                >
                    {parseUserAttributeIntoMessage(qCongratulatoryText, userAttributes)}
                </h1>
            )}
            {qText && (
                <h1 className={classNames('text-align--center huge', Styles.question)}>{qText}</h1>
            )}
            <div
                className={classNames(
                    'alignItems--center justifyContent-center',
                    Styles.fieldWrapper
                )}
                role="group"
            >
                {renderOptions()}
            </div>
        </main>
    );
};

export default RatingQuestion;

import React, { useEffect, useState } from 'react';

interface QuestionnaireProgressProps {
    currentQuestion: number;
    totalQuestions: number;
    Styles: React.CSSProperties;
}

const QuestionnaireProgress = ({
    currentQuestion,
    totalQuestions,
    Styles,
}: QuestionnaireProgressProps) => {
    const [progressWidth, setProgressWidth] = useState(
        Math.trunc((currentQuestion * 100) / totalQuestions).toString()
    );
    const [progressBarStyle, setProgressBarStyle] = useState({ width: `${progressWidth}%` });

    useEffect(() => {
        setProgressWidth(Math.trunc((currentQuestion * 100) / totalQuestions).toString());
    }, [currentQuestion]);

    useEffect(() => {
        setProgressBarStyle({ width: `${progressWidth}%` });
    }, [progressWidth]);

    return (
        <div className={Styles.questionnaireProgressBar}>
            <div style={progressBarStyle}></div>
        </div>
    );
};

export default QuestionnaireProgress;

import React from 'react';
import { Router } from '@reach/router';
import PrivateRoute from 'src/components/PrivateRoute';
import QuestionnairePageTemplate from 'src/templates/questionnaire-page';
import PhaseAndFunctionQuestionnairePageTemplate from 'src/templates/phase-and-function-questionnaire-page';
import NotFoundPage from 'src/pages/404';

const Questionnaires = () => (
    <Router>
        <PrivateRoute
            path="/questionnaires/phase-and-function"
            component={PhaseAndFunctionQuestionnairePageTemplate}
        />
        <PrivateRoute
            path="/questionnaires/phase-and-function/:updateResponses"
            component={PhaseAndFunctionQuestionnairePageTemplate}
        />
        <PrivateRoute
            path="/questionnaires/:category/:subcategory"
            component={QuestionnairePageTemplate}
        />
        {/* <PrivateRoute
            path="/questionnaires/:category/:subcategory/:update"
            component={QuestionnairePageTemplate}
        /> */}
        <NotFoundPage default />
    </Router>
);

export default Questionnaires;
